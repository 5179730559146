export function possuiPermissao (perm) {
	let userPermissions = [];
	if (localStorage.params)
		userPermissions = (JSON.parse(localStorage.params).grupoUsuario).split(",");

	return userPermissions.indexOf(perm) !== -1;
}

export function encontraTelaComPermissao (userPermissions, routes) {
	let routeName = "403";
	let permsAux = [];
	let childrenAux = [];
	let permsChild = [];

	const routesFiltered = routes.filter(r => r.meta && !r.meta.noRedirect);

	loopVerificacao:
	for (const route of routesFiltered) {
		childrenAux = route.children || [];
		permsAux = route.meta.perms || [];

		if (childrenAux.length > 0) {
			// Possui children
			for (const child of childrenAux) {
				if (!child.meta || child.meta.noRedirect || !child.name)
					continue;

				permsChild = child.meta.perms || [];
				if (!permsChild.length) {
					routeName = child.name;
					break loopVerificacao;
				} else {
					// Verifica se possui permissão
					for (const perm of permsChild) {
						if (userPermissions.indexOf(perm) !== -1) {
							routeName = child.name;
							break loopVerificacao;
						}
					}
				}
			}
		} else if (route.name) {
			if (!permsAux.length) {
				routeName = route.name;
				break loopVerificacao;
			} else {
				// Verifica se possui permissão
				for (const perm of permsAux) {
					if (userPermissions.indexOf(perm) !== -1) {
						routeName = route.name;
						break loopVerificacao;
					}
				}
			}
		}
	}

	return routeName;
}
