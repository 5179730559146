<template>
	<div id="app" class="background-div">
		<nav-bar
			@menu-button-click="toggleSideBar"
			@change-max-width="updateSideBarMaxWidth"
			:showBackButton="showBackButton"
			@back-button-click="goBack"
		/>
		<side-bar
			:hidden="sideBarHidden || noPermission"
			:max-width="sideBarMaxWidth"
			@link="sideBarLinkClicked"
			:links="parsedLinks"
			@resize="sideBarResized"
		/>
		<router-view/>
		<vue-snotify></vue-snotify>
		<EnterpriseName/>
		<div id="app-bottom-padding"></div>
	</div>
</template>

<script>
	import { mapMutations } from "vuex";

	import "@/assets/css/custom-style.css";
	import "@/assets/css/style.css";
	import "@/assets/scss/index.scss";

	import NavBar from "@/components/NavBar.vue";
	import SideBar from "@/components/SideBar.vue";

	import { copy } from "./helpers/common";
	import { AuthService } from "./services/auth";
	import { validateCPFCNPJ } from "@/helpers/validateIndentity";
	import EnterpriseName from "./components/EnterpriseName.vue";

	let itensHistorico = 0;

	const isMobile = () => window.innerWidth <= 790;

	export default {
		name: "App",
		components: {
			NavBar,
			SideBar,
			EnterpriseName
		},

		data () {
			return {
				sideBarWidth: 0,
				sideBarHidden: true,
				sideBarMaxWidth: 0,
				initialized: false,
				links: [
					{
						to: { name: "home" },
						name: "Dashboard",
						icon: "calendar",
						showPerms: ["DASHBOARD_CALENDARIO_V"]
					},
					{
						name: "Gerenciamento",
						icon: "editCustom", // sliders
						children: [
							{
								to: { name: "gruposUsuarios" },
								name: "Grupos de Usuários",
								icon: "users",
								showPerms: ["GER_V_GRUPO_USUARIO"]
							},
							{
								to: { name: "usuarios" },
								name: "Usuários",
								icon: "user",
								showPerms: ["GER_V_USUARIOS"]
							},
							{
								to: { name: "categoryExpenses" },
								name: "Categorias de Transação",
								icon: "package",
								showPerms: ["GER_V_CATEGORIAS"]
							},
							{
								to: { name: "accountClass" },
								name: "Tipos de Contas",
								icon: "folder",
								showPerms: ["GER_V_TIPOS"]
							},
							{
								to: { name: "account" },
								name: "Contas",
								icon: "fileText",
								showPerms: ["GER_V_CONTAS"]
							},
							{
								to: { name: "customer" },
								name: "Clientes",
								icon: "clientesCustom",
								showPerms: ["GER_V_CUSTOMER"]
							}

						]
					},
					{
						name: "Transações",
						icon: "transactionsCustom",
						children: [
							{
								to: { name: "incomeRecord" },
								name: "Rendas/Transferências",
								icon: "bankCustom",
								showPerms: ["GER_V_RENDAS"]
							},
							{
								to: { name: "expenseRecord" },
								name: "A Pagar/Pagamentos",
								icon: "arrowUpRight",
								showPerms: ["GER_V_AGENDAMENTOS"]
							},
							{
								to: { name: "receiveRecord" },
								name: "A Receber",
								icon: "arrowDownLeft",
								showPerms: ["GER_V_RECEIVE"]
							}
						]
					},
					{
						name: "Assinaturas",
						icon: "Star",
						children: [
							{
								to: { name: "subscription" },
								name: "Assinaturas",
								icon: "Award",
								showPerms: ["GER_V_ASSINATURAS"]
							}
						]
					},
					{
						name: "Relatórios",
						icon: "printer",
						children: [
							{
								to: { name: "accountBalance"},
								name: "Saldo das Contas",
								icon: "fileText",
								showPerms: ["REL_V_CONTAS"]
							}
						]
					},
					{
						to: { name: "perfilUsuario" },
						name: "Perfil de Usuário",
						icon: "user"
					}
				],
				parsedLinks: [],
				authService: new AuthService(),
				validateCPFCNPJ
			};
		},

		computed: {
			showBackButton () {
				const routes = ["home"];
				return !routes.includes(this.$route.name);
			},

			noPermission () {
				return [].some((el) => el === this.$route.name);
			}
		},

		watch: {
			noPermission () {
				this.sideBarHidden = this.noPermission;
				this.updateSideBarWidth(
					this.sideBarHidden || isMobile() ? 0 : this.sideBarWidth
				);
			}
		},

		methods: {
			goBack () {
				if (itensHistorico > 1)
					return this.$router.back();

				let found = false;
				for (let i = this.$route.matched.length - 1; i >= 0; i--) {
					const match = this.$route.matched[i];
					if (match.parent && match.parent.path + "/" !== match.path) {
						itensHistorico--;
						if (match.parent.parent) {
							this.$router.push(match.parent.path);
							found = true;
							break;
						}
					}
				}

				if (!found)
					this.$router.push({ name: "home" });
			},

			toggleSideBar () {
				if (this.noPermission) return;

				this.sideBarHidden = !this.sideBarHidden;
				this.updateSideBarWidth(
					this.sideBarHidden || isMobile() ? 0 : this.sideBarWidth
				);
			},

			sideBarResized (w) {
				this.sideBarWidth = w;
				this.updateSideBarWidth(
					this.sideBarHidden || isMobile() || this.noPermission ? 0 : w
				);
			},

			updateSideBarMaxWidth (w) {
				this.sideBarMaxWidth = w;
				if (!this.initialized) {
					// Detecta celular e encolhe a barra
					this.sideBarHidden = w < 100;
					this.initialized = true;
					this.sideBarWidth = 72;
					this.updateSideBarWidth(
						(this.sideBarHidden || isMobile() || this.noPermission) ? 0 : this.sideBarWidth
					);
				}
			},

			sideBarLinkClicked () {
				if (isMobile())
					this.sideBarHidden = !this.sideBarHidden;
			},

			_updateLinks (links) {
				for (const link of links) {
					if (link.children)
						link.children = this._updateLinks(link.children);
				}

				return links.filter(
					ln => !ln.showPerms || !ln.children || ln.children.length > 0
				);
			},

			updateLinks () {
				const links = copy(this.links);
				this.parsedLinks = this._updateLinks(links);
			},

			...mapMutations(["updateSideBarWidth"])
		},

		created () {
			this.updateLinks();

			this.$router.afterEach((to, from) => {
				if (from.name === null || to.name === "home")
					itensHistorico = 0;
				else
					itensHistorico++;
			});

			const usuarioLogado = this.authService.getModuleStorage();
			this.$store.commit("initUsuarioLogado", usuarioLogado);

			this.$validator.extend("noZeroCurrency", {
				validate: (value) => {
					const valueNumber = parseFloat(value.replace(",", "."));
					// return (!/^([0,.]+)0$/.test(valueNumber));
					if (valueNumber <= 0) return false;
					return true;
				}
			});

			this.$validator.extend("validateCPF", {
				validate: (value) => validateCPFCNPJ(value)
			});
		},

		provide () {
			return { parentValidator: this.$parent.$validator };
		}
	};
</script>

<style scoped>
	#app-bottom-padding {
		height: 80px;
	}

	@media (max-width: 790px) {
		#app-bottom-padding {
			max-height: 0px;
		}
	}

	.background-div {
		position: absolute;
		width: 100%;
		height: 100vh;  /* Ajusta según sea necesario */
		overflow: auto !important;
		background-image: url('./assets/img/background.png');
		background-size: cover;  /* Ajusta el tamaño de la imagen */
		background-position: center;  /* Centra la imagen */
		background-repeat: no-repeat;  /* Evita que la imagen se repita */
	}
</style>

<style>
	.text-ellipsis {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow-x: hidden;
	}

	.container {
		max-width: 98%;
	}
</style>
