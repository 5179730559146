import Vue from "vue";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import "bootstrap/dist/js/bootstrap.bundle.js";

import { BootstrapVue } from "bootstrap-vue";
Vue.use(BootstrapVue);

import "nprogress/nprogress.css";

import iconsPlugin from "./plugins/iconsPlugin";
Vue.use(iconsPlugin);

import skeleton from "tb-skeleton";
import "tb-skeleton/dist/skeleton.css";
Vue.use(skeleton);

import VueSweetalert2 from "vue-sweetalert2";
Vue.use(VueSweetalert2);

import Snotify from "vue-snotify";
import "vue-snotify/styles/material.css";
Vue.use(Snotify);

import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
Vue.component("multiselect", Multiselect);

import VueMultiselect from "vue-multi-select";
import "vue-multi-select/dist/lib/vue-multi-select.css";
Vue.component("vue-multi-select", VueMultiselect);

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
Vue.component("v-select", vSelect);

import store from "./store";

import App from "./App.vue";
import router from "./router";
import VeeValidate from "vee-validate";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faMoneyCheckDollar, faUserSecret, faMoneyBillTransfer } from "@fortawesome/free-solid-svg-icons";

library.add(faUserSecret);
library.add(faMoneyCheckDollar);
library.add(faMoneyBillTransfer);
Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;
Vue.config.devtools = (process.env.NODE_ENV || "development") === "development";

Vue.use(VeeValidate, {
	inject: true,
	fieldsBagName: "veeFields",
	errorBagName: "veeErrors",
	validity: true
});

// eslint-disable-next-line no-unused-vars
const vue = new Vue({
	router,
	store,
	render: h => h(App)
}).$mount("#app");
