<template>
	<div
		class="hero-header d-flex"
		v-bind:style="usuarioLogado ? `left: ${Math.floor(sideBarWidth) + 16}px;` : 'left: 16px; top: 85%'"
	>
		<div v-if="!usuarioLogado" class="d-flex" style="align-items: baseline;">
			<h2>
				<span class="name">Informática</span>
				<span class="last-name">SG</span>
			</h2>
			<p class="slogan">Soluções de TI na ponta dos seus dedos</p>
		</div>
	</div>
</template>

<script>
	import { mapState } from "vuex";
	export default {
		name: "EnterpriseName",
		props: {
			width: {
				type: String
			},
			fluid: {
				type: Boolean,
				default: false
			}
		},
		computed: mapState(["sideBarWidth", "usuarioLogado"])
	};
</script>

<style scoped>
	.hero-header {
		position: absolute;
		font-size: .9rem !important;
		color: white;
		align-items: flex-end;
		margin-top: 1.75rem;
		transition: all ease 0.5s;
	}

	.last-name {
		margin-left: 2px;
	}

	.slogan {
		margin-left: 3px;
		font-style: italic;
	}

</style>