const env = process.env.NODE_ENV;
let API = null;

switch (env) {
	case "production":
		API = "/api";
		break;
	case "staging":
		API = "http://127.0.0.1:3000/api";
		break;
	default:
		API = "http://localhost:3000/api";
		break;
}
console.log(env, API);

export default {
	API
};
